<template>
    <div id="gamescreen">
       <canvas id="background-layer" width="600" height="337"></canvas>
      <canvas id="drum-layer" width="600" height="337"></canvas>
    </div>

</template>

<script>
import drumKit, {cleanUp} from "../gameFiles/drumKitIndex";
export default {
    name: 'drumKit',
    mounted() {
        drumKit();
    },
    beforeDestroy() {
        cleanUp();
    }
}
</script>

<style lang="scss" scoped>
   #gamescreen canvas { 
        position: absolute;
        top: 0;
        left: 0;
    } 

    #gamescreen {
        position: relative;
        width: 600px;
        height: 337px;
    }

    #background-layer{
        background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(121,12,154,1) 100%);
    }
</style>