
let selectPos;
let ctx;
let canvas;
let images = [];
const imagesURL = ["cymbalLeft.svg","cymbalLeftHit.svg", "cymbalRight.svg","cymbalRightHit.svg","snare.svg", "snareHit.svg",
"highHat.svg","highHatHit.svg","kickDrum.svg", "kickDrumHit.svg","tomLarge.svg", "tomLargeHit.svg","tomMid.svg","tomMidHit.svg",
"tomSmall.svg", "tomSmallHit.svg","backdrop.svg"];
let drumKitSounds = {
    snare: require('@/assets/audio/drumKit/snareDrum.mp3'),
    highHat: require('@/assets/audio/drumKit/highHat.mp3'),
    cymbal: require('@/assets/audio/drumKit/cymbal.mp3'),
    kick: require('@/assets/audio/drumKit/kickDrum.mp3'),
    tom: require('@/assets/audio/drumKit/tomDrum.mp3'),
}
let drumKitParts = [
    {
        name: 'cymbalLeft',
        imageNum: 0,
        sound: 'cymbal',
        xPos: 190,
        yPos: 170,
        hit: {
            beenHit: false,
            x: 140,
            y: 45,
            width: 105,
            height: 48,
        }
    },
    {
        name: 'cymbalRight',
        imageNum: 2,
        sound: 'cymbal',
        xPos: 400,
        yPos: 160,
        hit: {
            beenHit: false,
            x: 350,
            y: 35,
            width: 105,
            height: 48, 
        }
    },
    {
        name: 'kickDrum',
        imageNum: 8,
        sound: 'kick',
        xPos: 300,
        yPos: 230,
        hit: {
            beenHit: false,
            x: 240,
            y: 175,
            width: 120,
            height: 120, 
        }
    },
    {
        name: 'tomSmall',
        imageNum: 14,
        sound: 'tom',
        xPos: 265,
        yPos: 130,
        hit: {
            beenHit: false,
            x: 240,
            y: 95,
            width: 55,
            height: 70,
        }
    },
    {
        name: 'tomMid',
        imageNum: 12,
        sound: 'tom',
        xPos: 340,
        yPos: 130,
        hit: {
            beenHit: false,
            x: 310,
            y: 95,
            width: 65,
            height: 70,
        }
    },
    {
        name: 'tomLarge',
        imageNum: 10,
        sound: 'tom',
        xPos: 420,
        yPos: 260,
        hit: {
            beenHit: false,
            x: 365,
            y : 170,
            width: 95,
            height: 95,
        }
    },
    {
        name: 'highHat',
        imageNum: 6,
        sound: 'highHat',
        xPos: 140,
        yPos: 200,
        hit: {
            beenHit: false,
            x: 90,
            y: 115,
            width: 100,
            height: 45,
        }
    },
    {
        name: 'snare',
        imageNum: 4,
        sound: 'snare',
        xPos: 180,
        yPos: 255,
        hit: {
            beenHit: false,
            x: 145,
            y: 165,
            width: 90,
            height: 60,
        }
    },
]


export default function () {
    canvas = document.getElementById('drum-layer');
    ctx = canvas.getContext('2d');
    loadImages();
    canvas.addEventListener('mousemove', onMouseMove, false);
    canvas.addEventListener('mousedown', checkDrumHit, false);
}

export function cleanUp() {
    canvas.removeEventListener('mousemove', onMouseMove);
    canvas.removeEventListener('mousedown', checkDrumHit);
}

let onMouseMove = function(evt) {
    selectPos = getSelectPos(evt);
}

function getSelectPos(evt) {
    return {
        xPos: evt.offsetX,
        yPos: evt.offsetY,
    };
}

let checkDrumHit = function(evt) {
    evt.preventDefault()
    drumKitParts.forEach((part) =>{
        if (Math.abs((part.hit.x + (part.hit.width / 2)) - selectPos.xPos ) < (part.hit.width / 2)) {
            if (Math.abs((part.hit.y + (part.hit.height / 2)) - selectPos.yPos ) < (part.hit.height / 2)) {
              playSound(part);
            }
        }
    })
}

async function playSound(drum) {
    drum.hit.beenHit = true;
    drawDrums();
    const sound = new Audio(drumKitSounds[drum.sound]);
    await sound.play();
    drum.hit.beenHit = false;
    drawDrums();
}

function drawBackground() {
    const canvas = document.getElementById('background-layer');
    const ctx = canvas.getContext('2d');
    drawImage(images[16],425, 250, 2, ctx);
}

function drawDrums() {
    let drumImage;
    ctx.clearRect(0, 0, 600, 337);
    drumKitParts.forEach((part) =>{
        ctx.save();
        drumImage = part.hit.beenHit ? part.imageNum + 1 : part.imageNum;
        drawImage(images[drumImage], part.xPos, part.yPos, 1.2, ctx);
        ctx.restore();
    })
}

function drawImage(image, x, y, scale = 1,definedCtx = ctx) {
    definedCtx.setTransform(scale, 0, 0, scale, x, y); 
    definedCtx.drawImage(image, Math.floor(-image.width / 2), Math.floor(-image.height / 2));
}

function completeUrl(urlList) {
    if (urlList === null) return;
    return urlList.map(URL => require('@/assets/images/drumKit/' + URL));
}

function loadImages() {
    let imageURL = [...completeUrl(imagesURL)];
    let imageCount = 0;
    imageURL.forEach(function(src) {
        const image = new Image();
        image.src = src;
        image.onload = function() { 
            imageCount += 1;
            if(imageCount === imageURL.length){
                drawBackground();
                drawDrums();
            }
        }
        images.push(image); 
    });
}
