<template>
    <div class="icon-container">
        <img :src="imageSource">
        <div class="icon-text">
            <p>{{text}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UnableToDisplay',
     props: {
        text: {
            default: '',
            type: String,
        },
        imageSource: {
            required: true,
            type: String,
        },
     }
}
</script>

<style lang="scss" scoped>
    .icon-container {
        width: 100%;
        border: 8px solid rgb(14, 19, 130);
        border-radius: 10px;
        display: row;
        padding: 70px 5px;
        color: rgb(255, 220, 20);
        text-align: center;
    }

</style>