<template>
<div>
    <ProjectPageTemplate
        projectTitle="DRUM KIT"
        :imageSource="require('@/assets/images/projectLinks/drumKitLink.png')"
        shortDescription="Click or touch on the piece of drum kit to play its sound and run the animation. This was my first attempt at adding sounds to a project and thought this would be a good starting point. Sounds were taken from Scratch."
        longDescription= "On loading an event is set up to check on click/touch if its within the area of a piece of kit. Each piece of equipment is its own SVG file and will play its sound and change to another image when hit. Sounds were converted from wav to mp3 format as this is more compatible with web browsers.<br>
                        <br>Had future plans to allow for more than one sound to be played at a time and had some ideas on how to allow for the record of drumming for playback."
        :importedLinkData="techLinksData"
    >
    <template v-slot:main-content>
        <div class='content-container'>
            <drumKit></drumKit>
        </div>
        <div class="rotate-screen">
            <UnableToDisplay
                :imageSource="require('@/assets/images/rotate.svg')"
                text="ROTATE SCREEN"
            />
        </div>
    </template>
    </ProjectPageTemplate>
</div>

</template>

<script>
import ProjectPageTemplate from "@/components/ProjectPageTemplate";
import drumKit from '../components/DrumKitComp.vue';
import UnableToDisplay from "@/components/page_elements/UnableToDisplay.vue"
import techLinks from '@/data/techLinks.js'
export default {
    name: 'DrumKit',
    components: {
        ProjectPageTemplate,
        drumKit,
        UnableToDisplay,
    },
    data() {
        return {
            techLinksData: [
                techLinks.html, 
                techLinks.javascript,
                techLinks.scratch
            ],
        }
  }
}
</script>

<style lang="scss" scoped>

@media screen and (orientation:portrait)  {
    @media screen and (max-width: 620px) {
        .content-container {
            display: none;
        }
        .rotate-screen.rotate-screen {
            display: flex;
        }
    }
}

.rotate-screen {
    display: none;
    width: 100%;
}
</style>